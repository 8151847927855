@use 'styles/colors';

$max-height: 80svh;

.modal {
    padding: 0;

    .modal-content {
        display: flex;
        margin: 0.5rem;

        .warning {
            border-left-width: 8px;
            border-left-color: colors.$warning;
            border-left-style: solid;
            color: colors.$warning;
        }
    }

    .title {
        font-weight: bold;
    }

    .modal-message {
        width: min-content;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;

        display: flex;
        flex-direction: column;
    }

    .modal-actions {
        margin-top: 1.5rem;
        padding: 1rem;
        border-top-color: colors.$border;
        border-top-style: solid;
        border-top-width: 1px;
    }
}
