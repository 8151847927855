@use 'styles/colors';
@use 'styles/borders';

.expandedQuestion {
    padding: 1.5rem;

    .buttonBar {
        padding-bottom: 1.5rem;
    }

    .content {
        display: flex;
        padding: 1.5rem;
        flex-wrap: wrap;
        gap: 1.5rem;
        align-items: flex-start;
        flex-shrink: 0;
        @include borders.rounded();
        @extend %thin;

        .fieldHeading {
            color: colors.$base-black;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.4175rem;
            width: 14rem;
        }

        .fieldValue {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4175rem;
        }
    }
}
