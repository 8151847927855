@use 'styles/colors';

$compact-font-size: 0.875rem;
$compact-height: 2rem;
$compact-textarea-height: 7.5rem;

%compact {
    line-height: normal;
    font-size: $compact-font-size;
}

%compact-input {
    height: $compact-height;
    width: unset;

    @extend %compact;
}

%compact-textarea {
    height: $compact-textarea-height;
    width: unset;

    @extend %compact;
}
