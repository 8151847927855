@use 'styles/colors';

.add-new-page {
    background-color: colors.$background;
    padding: 0 1.5rem 6rem;
    min-height: 90vh;

    .breadcrumb-wrap {
        padding: 1.5rem 0;
    }

    form.usa-form {
        max-width: unset;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .add-new-page__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        min-width: 960px;
        max-width: 960px;
        background-color: colors.$base-white;
        margin: 1.2rem 0;
        justify-content: center;
        label {
            font-size: 1rem;
        }
        .pages-breadcrumb {
            padding-top: 1.4rem;
            padding-left: 1.4rem;
        }

        .add-new-page__content {
            width: 482px;
            padding: 2rem 0 4rem;
            margin: 0 auto;

            .fields-info {
                font-size: 0.875rem;
                .mandatory-indicator {
                    color: colors.$mandatory;
                }
            }
            p {
                font-size: 0.875rem;
                line-height: 1.4rem;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
            }
            h2 {
                text-align: center;
                margin-bottom: 3rem;
            }
            h4 {
                font-weight: 400;
                margin-bottom: 0;
            }
            a {
                text-decoration: none;
            }
            .usa-button {
                margin-top: 0.4rem;
                font-size: 0.95rem;
                text-decoration: none;
                &--unstyled:nth-of-type(1) {
                    padding: 0;
                }
            }
            button {
                padding: 0.5rem 0.75rem;
                p {
                    margin: 0;
                    font-weight: 700;
                    color: colors.$primary;
                }
            }
        }

        .unsupported-event-type-message {
            border: 4px solid var(--warm-accent-accent, #fa9441);
            background: rgba(250, 243, 209, 0.44);
            display: flex;
            margin-top: 1.5rem;
            padding: 1.5rem;
            color: var(--base-black, #000);
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.62rem;
        }
    }
    .add-new-page__buttons {
        position: fixed;
        display: flex;
        bottom: 0;
        width: 100%;
        padding: 1.5rem;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid var(--base-lightest, #f0f0f0);
        background: var(--base-white, #fff);

        .usa-button {
            margin-top: 0 !important;
        }
    }
}

.add-condition-modal {
    min-width: 85%;
    height: 80svh;

    .usa-modal__content {
        height: 80svh;
        overflow: hidden;
    }
    .usa-modal__main {
        height: 80svh;
    }
}
