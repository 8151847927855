@use 'styles/colors';

.pages-breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    display: flex;
    width: 100%;
    align-items: center;
    color: colors.$primary;

    div {
        display: flex;

        svg {
            margin: -0.25rem 0.5rem 0 0;
            cursor: pointer;
        }
    }

    h4 {
        cursor: pointer;
        margin: 0;
    }
    h5 {
        margin: 0;
        font-size: 1rem;
        color: colors.$primary-darker;
    }
}
