@use 'styles/colors.scss';
@use 'styles/borders.scss';

.preference {
    display: flex;
    padding: 0.625rem 1rem;
    align-items: flex-start;
    gap: 0.5rem;

    color: colors.$base-darkest;

    font-size: 0.875rem;
    line-height: 162%;

    &:not(:last-child) {
        @extend %thin-bottom;
    }

    cursor: pointer;

    .placeholder {
        min-width: 1.25rem;
    }

    .indicator {
        color: colors.$primary;
        width: 1.25rem;
        height: 1.25rem;
    }
}
