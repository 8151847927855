@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html body {
    font-family: Public Sans !important;

    [class*='font-sans-'] {
        font-family: Public Sans, Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
    }
    [class*='usa-'] {
        font-family: Public Sans, Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
    }
    [class*='text-'] {
        font-family: Public Sans, Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
    }
    [class*='-3xs'] {
        font-size: 0.7rem;
    }
    [class*='-2xs'] {
        font-size: 0.95rem;
    }
}
