@use 'styles/colors';
.alertMessage {
    display: flex;
    gap: 0.75rem;
    padding: 1rem 1rem 1rem 0.75rem;
    border-left-style: solid;
    border-left-width: 8px;

    &.information {
        border-left-color: colors.$info;
        background-color: colors.$info-lighter;
    }

    &.success {
        border-left-color: colors.$success;
        background-color: colors.$success-lighter;
    }

    &.warning {
        border-left-color: colors.$warning;
        background-color: colors.$warning-lighter;
    }

    &.error {
        border-left-color: colors.$error;
        background-color: colors.$error-lighter;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 0.12rem;

        .title {
            color: colors.$base-darkest;
            font-size: 1.375rem;
            font-weight: 700;
        }
    }
}
