.address-entry-form-container {
    .suggestion-list-container {
        min-width: 224px;
        margin-right: -8px;
        border: 1px solid black;
        border-top: 0;
        border-radius: 0;
        .usa-nav__submenu-item .address-suggestion-line:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
