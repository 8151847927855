@use 'styles/colors';

.addQuestion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .addQuestionHeader {
        display: flex;
        align-items: center;
        svg {
            cursor: pointer;
            margin-right: 1rem;
        }
    }

    .scrollableContent {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading {
            padding: 2rem 0;
            text-align: center;
            h3 {
                font-size: 1rem;
                margin: 0;
            }
            .fieldInfo {
                margin-top: 0.25rem;
                font-size: 0.875rem;
            }

            .mandatory {
                color: colors.$mandatory;
            }
        }
        .formContainer {
            width: 35rem;
        }
    }
}
