@use 'styles/colors';
@use 'styles/animation';

.subsection {
    height: auto;
    position: relative;
    .toggle {
        cursor: pointer;
    }
    .handle {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tile {
        background-color: colors.$base-white;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.9rem 1rem 0.9rem 5.0rem;
        z-index: 400;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .questions {
        transition: animation.$transition-1;
        &.closed {
            height: 0;
            display: none;
        }
        .droppable {
            min-height: 1px;
        }
    }
    &.hidden {
        height: 0px;
    }
    &.dragging .tile{
        border: 1px solid colors.$primary;
        filter: drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
    }
}
