ul.suggestions {
    margin-top: 0;
    list-style: none;
    border: 1px solid black;
    border-top: 0;
    padding: 0;
    overflow: hidden;
    max-height: 300px;
    overflow-y: auto;
}
