@use 'styles/colors';

.valuesetForm {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .formWrapper {
        display: flex;
        flex-direction: column;
        width: 40rem;
        padding-bottom: 8rem;

        .heading {
            color: colors.$base-darker;
            text-align: center;
            font-size: 1.375rem;
            font-weight: 700;
        }

        .subHeading {
            color: colors.$base-darkest;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.4175rem;
        }

        .radioButtons {
            display: flex;
            gap: 1.38rem;
            margin-top: 1.5rem;
        }

        .mandatory {
            color: colors.$mandatory;
        }

        input {
            max-width: none;
        }
    }
}
