@use 'styles/colors';
@use 'styles/borders';

.dataElementsForm {
    overflow: auto;
    height: 100%;
    width: 100%;

    table {
        width: 100%;
        table-layout: auto;
        border-collapse: collapse;
        font-size: 0.875rem;

        thead {
            position: sticky;
            top: 0;
            z-index: 20;
            text-align: left;

            .border {
                th {
                    height: 1px;
                    padding: 0;
                    background-color: colors.$base;
                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
                }
            }

            .calculatedColumn {
                min-width: 12rem;
            }

            .numericColumn {
                width: 10rem;
            }
        }

        tr th,
        td {
            padding: 0.62rem 1rem;
        }

        .checkbox {
            width: 3.25rem;
            label::before {
                margin-top: -0.75rem;
            }
        }

        tbody tr:nth-child(odd) {
            background-color: colors.$base-lightest;
        }

        @extend %thin-bottom;
    }
}
