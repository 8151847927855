@use 'styles/colors';
@use 'mixins/viewports';
@use 'styles/borders';
@use 'styles/modal';

@import '@trussworks/react-uswds/lib/uswds.css';
@import '@trussworks/react-uswds/lib/index.css';

$theme-image-path: '~uswds/src/img';
$theme-font-path: '~uswds/src/fonts';
$theme-hero-image: '~uswds/src/img/hero.png';

table.usa-table {
    overflow-x: auto;
    table-layout: fixed;
    width: 100%;
    thead {
        width: 100%;
    }
}
@include viewports.tablet {
    table.usa-table {
        display: block;
        table-layout: auto;
        overflow-x: auto;
    }
}

// Modal styling override
.usa-modal-wrapper.is-visible {
    max-height: 100vh;
    .usa-modal-overlay {
        max-height: 100vh;
        z-index: 10001;
        .usa-modal--lg {
            max-height: 85vh;
            .usa-modal__content {
                max-height: 85vh;
            }
        }
        .usa-modal {
            max-height: modal.$max-height;

            .usa-modal__content {
                max-height: modal.$max-height;
                .usa-modal__main {
                    .usa-modal__footer {
                        margin-top: 0;
                        @extend %thin-top;
                    }
                }
            }
        }
    }
}
.usa-modal {
    border-radius: 0.3rem;
}
.usa-modal__main {
    margin: 0 !important;
    padding: 0 !important;
}

.usa-modal__content {
    padding: 0 !important;
    position: relative;

    .usa-button-group {
        justify-content: flex-end;
    }
}
.usa-modal__close {
    position: absolute;
    top: 40px;
    right: 0;
}

.usa-modal-overlay {
    overflow: hidden;
}

// Default button styling
.usa-button {
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;

    height: 2.75rem;
    min-width: 2.75rem;
    line-height: 125%;

    &:has(svg) {
        align-items: center;
        padding: 0.75rem 1.25rem;
        display: inline-flex;
        gap: 0.3125rem;
    }
}

.usa-button:disabled {
    color: colors.$disabled-darkest !important;
    background-color: colors.$disabled !important;
    cursor: default;
}

.usa-button--outline-disabled,
.usa-button--outline:disabled {
    background-color: transparent !important;
    color: colors.$disabled !important;
    border: 2px solid colors.$disabled !important;
}

.usa-pagination {
    background-color: transparent !important;
}

.usa-pagination__button:active,
.usa-pagination__button:focus,
.usa-pagination__button:hover {
    border: 1px solid colors.$primary-dark !important;
}

.historyPagination {
    overflow: scroll;

    &.usa-pagination {
        justify-content: left;
    }
    & nav {
        border-bottom: none;
    }
    .usa-button {
        &:has(svg) {
            padding: 0 !important;
        }
        &:not([disabled]):focus {
            outline: none;
        }
    }
}

.usa-button-group--segmented {
    .usa-button--secondary:not(.usa-button--outline) {
        background-color: colors.$primary-darker;
        color: colors.$base-white;
    }
}

.usa-pagination {
    .usa-button {
        padding: 0;
        font-weight: 400;
    }
    .usa-pagination__arrow .usa-button {
        font-weight: 700;
    }
}

input.usa-input--error:focus {
    outline: none;
}
.usa-input-mask {
    display: flex;

    input {
        flex-grow: 1;
    }

    .usa-input-mask--content {
        display: none;
    }
}

.usa-date-picker__calendar {
    min-width: 300px !important;
}

.from {
    --calendar-left: 0;
}

.usa-date-picker__wrapper {
    .usa-date-picker__external-input {
        flex-grow: 1;
        margin-right: 4px;
    }
    .usa-date-picker__button {
        margin: 0;
    }

    .usa-date-picker__calendar {
        left: var(--calendar-left, unset);
    }
}
