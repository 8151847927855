@use 'styles/colors';

.usa-alert {
    min-width: 450px;
    padding: 0.5rem 1rem 0.5rem 0.75rem !important;
    position: fixed;
    z-index: 99999+1;
    top: 15px;
    animation: from-right 0.3s;
    animation-fill-mode: both;

    .usa-alert__heading {
        text-transform: capitalize;
        font-size: 1.375rem !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
    }

    .usa-alert__body {
        margin-left: initial !important;
        background-color: inherit !important;
        font-size: 1rem !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 162% !important;
    }
}

.usa-alert--success {
    background-color: colors.$success-lighter !important;
    border-left-color: colors.$success !important;
}

.usa-alert--error {
    background-color: colors.$error-lighter !important;
    border-left-color: colors.$error !important;
}

.usa-alert--warning {
    background-color: colors.$warning-lighter !important;
    border-left-color: colors.$warning !important;
}

.usa-alert--info {
    background-color: colors.$info-lighter !important;
    border-left-color: colors.$info !important;
}

@keyframes from-right {
    from {
        right: -450px;
    }
    to {
        right: 15px;
    }
}
