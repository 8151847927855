.toggle-btn {
    //vertical center the toggle button
    display: flex;
    align-items: center;
    .switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 16px;
        input {
            opacity: 0;
            width: 0;
            height: 0;
            &:checked {
                & + .slider {
                    background-color: #73b3e7;
                    &:before {
                        -webkit-transform: translateX(20px);
                        -ms-transform: translateX(20px);
                        transform: translateX(20px);
                        background-color: #0050d8;
                    }
                }
            }
            &:focus {
                & + .slider {
                    box-shadow: 0 0 1px #73b3e7;
                }
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: -1px;
            bottom: -2px;
            background-color: #ffffff;
            border: solid rgba(204, 204, 204, 0.3);
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        &.round {
            border-radius: 34px;
            &:before {
                border-radius: 50%;
            }
        }
    }
}
