@use 'styles/colors';

.segmentedButtons {
    display: flex;
    flex-direction: column;
    &.hidden {
        label span{
            color: colors.$secondary;
        }
    }
    .buttons {
        display: flex;
        padding-top: 0.5rem;
        button {
            display: flex;
            column-gap: 4px;
            color: colors.$primary;
            background-color: colors.$base-white;
            border-top: 0.125rem solid colors.$primary;
            border-bottom: 0.125rem solid colors.$primary;
            box-shadow: none;
            border-radius: 0;
            border-left: 0.125rem solid  colors.$primary;
            margin: 0;
            padding: 0.75rem 1.25rem;
            align-items: center;
            &.active {
                color: colors.$base-white;
                background-color: colors.$primary-darker;
                border-color: colors.$primary-darker;
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
            &:hover {
                box-shadow: none;
                border-bottom: 0.19rem solid colors.$primary;
            }
            &:not([disabled]):focus{
                outline: none;
            }
            &:first-of-type {
                border-radius: 0.25rem 0 0 0.25rem;
                border-left: 0.125rem solid colors.$primary;
            }
            &:last-of-type {
                border-radius: 0 0.25rem 0.25rem 0;
                border-right: 0.125rem solid colors.$primary;
            }
        }
    }
}