.change-valueset-modal {
    height: 85vh;
    min-width: 85%;
    overflow: hidden;
    .usa-modal__content {
        height: 85vh;
        overflow: hidden;
        .usa-modal__main {
            height: 100%;
        }
    }
}
