@use 'styles/colors';

form.saveTemplate {
    max-width: unset;
    width: 100%;
    .body {
        padding: 0.5rem 1.5rem 1.5rem;
        p span {
            color: colors.$secondary;
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        padding: 1.5rem;
        border-top: 1px solid colors.$base-lighter;
        button {
            margin: 0;
        }
    }
}