@use 'styles/colors';
@use 'styles/borders';

.library {
    border-radius: 5px;
    background-color: colors.$base-white;

    @extend %thin;

    header {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem;

        @extend %thin-bottom;

        h1 {
            font-size: 1.5rem;
            margin: 0;
        }
    }

    @extend %thin-bottom;
}
