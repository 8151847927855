@use 'styles/colors';
@use 'styles/borders';

nav {
    position: sticky;
    top: 0;

    .navTitle {
        padding: 0;
        font-size: 0.875rem;
        font-weight: 700;
    }

    .navOptions {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        @include borders.bordered('border-left');

        a {
            padding: 0.5rem 1rem;
            text-decoration: none;
            border-left: 4px solid transparent !important;

            &:focus {
                outline: none !important;
                background-color: transparent !important;
            }

            &.active {
                color: colors.$base-darkest;
                border-left: 4px solid colors.$base-darkest !important;
                transition: 0.2s ease-in;
            }

            &.active ~ a {
                color: colors.$primary !important;
                border-left: 4px solid transparent !important;
            }
        }
    }
}
