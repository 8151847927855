.itemgroup {
    margin-bottom: 0.5em;

    header {
        font-weight: bold;
    }
    
    p {
        margin-top: 0;
        margin-bottom: 0;
        word-break: break-word;
        white-space: pre-wrap;
    }
}

