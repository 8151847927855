.helpText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    width: 20rem;

    p {
        margin: 0;
    }

    ul li {
        padding-bottom: 1rem;
    }
}
