@use 'styles/colors';
@use 'styles/borders';

.management {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & > header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: colors.$base-white;

        @extend %thin-bottom;

        h1 {
            margin: 0;
        }
    }

    & > .bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
            h2 {
                display: flex;
                gap: 0.25rem;
                align-items: center;
                font-weight: 400;
                font-size: 1rem;
                margin: 0;
            }

        .mode {
            padding: 0.25rem 0.5rem;
            border-radius: 0.125rem;
            text-transform: uppercase;
            background: colors.$info;
        }
        .modeEditing {
            padding: 0.25rem 0.5rem;
            border-radius: 0.125rem;
            text-transform: uppercase;
            background: colors.$warning;
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
        }
    }
}

.content {
    padding: 0 1rem;
}
