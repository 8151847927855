@use 'styles/colors';

.breadcrumb {
    ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 0.5rem;
        li {
            display: inline-block;
            color: colors.$primary-darker;
            font-weight: 700;
            line-height: 162%;
            position: relative;
            margin-right: 1rem;
            &:not(:last-child)::after {
                display: inline-block;
                content: '/';
                font-weight: 400;
                padding: 0 1ch;
                color: colors.$base;
                position: absolute;
                top: 0;
                right: -1.5rem;
            }

            a {
                cursor: pointer;
                display: flex;
                gap: 0.5rem;
                svg {
                    vertical-align: text-bottom;
                }
            }
        }
    }

    div {
        display: flex;

        svg {
            margin: -0.25rem 0.5rem 0 0;
            cursor: pointer;
        }
    }

    h4 {
        cursor: pointer;
        margin: 0;
    }
    h5 {
        margin: 0;
        font-size: 1rem;
        color: colors.$primary-darker;
    }
}
