@use 'styles/colors';

.form {
    max-width: unset !important;
    width: 100%;
    position: relative;
    height: calc(100% - 3.75rem);
    overflow: hidden;
    .body {
        width: 100%;
        padding: 1.5rem;
        max-height: calc(100% - 5.5rem);
        overflow-y: auto;
        .required span {
            color: colors.$error;
        }
        .conditions {
            padding: 0;
            p {
                width: 100%;
                padding-bottom: 0.5rem;
                margin-left: 0.5rem;
                border-bottom: 1px solid colors.$base-light;
            }
        }
    }
    .footer {
        border-top: 1px solid colors.$base-lighter;
        position: absolute;
        padding: 1.5rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;
        bottom: 0;
        width: 100%;
        height: 5.5rem;
        background-color: colors.$base-white;
        border-radius: 0 0 0.5rem 0.5rem;
        button {
            margin: 0;
        }
    }
}