@use 'styles/colors';
@use 'styles/borders';

.passListEntry {
    display: flex;
    gap: 0.75rem;
    @extend %thin-top;
    padding: 0.15rem 0;
    cursor: pointer;

    &.activeEntry {
        background: colors.$primary-lightest;
        .border {
            border-left: 0.25rem solid colors.$primary;
        }
    }

    .border {
        width: 0.25rem;
        height: 100%;
        border-radius: 1.875rem;
    }

    .info {
        display: flex;
        flex-direction: column;
        padding: 0.62rem 0;

        .name {
            color: colors.$primary;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.25rem;
        }

        .status {
            padding: 0.125rem 0.5rem;
            border-radius: 0.125rem;
            width: fit-content;
            margin-top: 0.5rem;
            font-size: 0.875rem;
            font-weight: 400;

            &.active {
                background: colors.$open;
                color: #fff;
            }

            &.inactive {
                background: colors.$disabled-light;
            }
        }
    }
}
