@use 'styles/colors';

.form {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
    h4 {
        font-size: 1.05rem;
    }
}

.radioButtons {
    display: flex;
    gap: 1.38rem;
    margin-top: 1.5rem;
}

.helpText {
    color: colors.$base-darkest;
    font-size: 0.875rem;
    line-height: 1.4175rem;
    margin-top: 0.38rem;
}

.yesNoRadioButtons {
    display: flex;
    gap: 4rem;
}

.textaArea {
    height: 2.5rem;
    min-height: 2.5rem;
}

.buttonGroup {
    justify-content: flex-start !important;
    margin-top: 0.25rem;
}

.fieldInfo {
    margin-top: 0.25rem;
    font-size: 0.875rem;
}

.toggleGroup {
    display: flex;
    gap: 1rem;
}

.toggleLabel {
    margin-top: 1.5rem;
}

.mandatory {
    color: colors.$mandatory;
}

.valuesetSearchButton {
    margin-top: 1.5rem;
}