@use 'styles/colors';
@use 'styles/borders';

.business-rule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.5rem;
    border-bottom: 1px solid var(--Base-lighter, #dcdee0);
    background-color: colors.$base-white;
}

.business-rules-library {
    .search-description-block {
        p {
            margin: 32px 32px 8px;
        }
        color: var(--base-black, #000);
        font-family: Public Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    position: relative;

    .business-rules-library__table {
        .padding-x-2.search-box {
            display: none;
        }
        background-color: colors.$base-white;
        margin: 1.5rem;
        border-radius: 3px;
        border: 1px solid colors.$base-lightest;
        table.usa-table {
            border-radius: 5px;
            @extend %thin;
            border-bottom: 1px solid rgba(27, 27, 27, 0.95);
            background: var(--base-white, #fff);
        }
        .check-title {
            display: block;
            margin-left: 64px;
        }
        thead th {
            &:nth-of-type(1) .table-head {
                min-width: 100px;
            }
            &:nth-of-type(3) {
                min-width: 250px;
            }
            &:nth-of-type(5) {
                width: 350px;
            }
        }
        tbody tr {
            border-bottom: 1px solid #1b1b1b;
            td {
                border: 0;
                .usa-checkbox__label::before {
                    margin-top: 0.18rem;
                }
            }
        }
    }
    .footer-action {
        display: none;
        //display: flex;
        padding: 24px 0;
        justify-content: flex-end;
        align-items: center;
        .question-action-btn {
            .cancel-btn {
                border-radius: 4px;
                border: 2px solid #005ea2;
                background: rgba(255, 255, 255, 0);
                color: #005ea2;
                text-align: center;
                font-family: Public Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .submit-btn {
                border-radius: 4px;
                text-align: center;
                border: 2px;
                font-family: Public Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .usa-button {
                &:disabled {
                    border: 2px solid #c9c9c9;
                    background: #c9c9c9;
                }
            }
        }
    }
}

.usa-modal--lg {
    .usa-modal__main {
        max-width: unset !important;
    }
    .usa-modal__heading {
        padding: 24px;
        font-family: Public Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.business-rules-library__header {
    display: flex;
    background: inherit !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .search-filter {
        display: flex;
    }
    &.outer-search-box {
        border-radius: 5px;
        border-bottom: 1px solid colors.$base-lighter;
        background: colors.$base-lightest;
        padding: 16px;
        margin-bottom: 16px;
    }
    .search-tag {
        display: flex;
        gap: 10px;
        .tag-cover {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 4px 6px 4px 2px;
            border-radius: 2px;
            color: #ffffff;
            background: #005ea2;
            .usa-icon {
                cursor: pointer;
            }
        }
    }
    button {
        padding: 9px 16px;
        height: 40px;
        p {
            margin: 0;
            font-size: 16px;
        }
        &:first-of-type {
            border-radius: 0 4px 4px 0;
            padding: 9px;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    .input {
        input {
            margin: 0;
            width: 252px;
        }
        span {
            display: none;
        }
    }
}

.search-desc {
    color: var(--base-base, #71767a);
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
}

.page-name {
    color: #000000;
    cursor: pointer;
    font-weight: 400;
}

.event-text {
    color: #3d4551;
}
