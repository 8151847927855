@use 'styles/colors';
@use 'styles/borders';
.blockingCriteriaForm {
    background-color: colors.$base-white;
    @extend %thin;

    & > header {
        padding: 1.5rem;
        @extend %thin-bottom;

        .headerText {
            color: colors.$base-darkest;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.4175rem;
            margin: 0;
        }
    }
}
