@use 'styles/colors';
@use 'styles/borders';

.card {
    @extend %thin;
    background-color: colors.$base-white;

    & > header {
        display: flex;
        padding: 1.5rem;
        justify-content: space-between;
        align-items: center;
        @extend %thin-bottom;
    }
}
