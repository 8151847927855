.bounded {
    max-height: 42px;
    overflow: hidden;
}
.unbounded {
    max-height: unset;
}
.button {
    padding: 0 !important;
    margin-left: auto !important;
    width: 9ch !important;
    text-decoration: unset !important;
    cursor: pointer;
}
