@use 'styles/colors';

.layout {
    min-height: 100%;

    background-image: url(./landing.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: colors.$base-white;

    & > header {
        background-color: colors.$primary-darker;
        padding: 1.5rem;
        color: colors.$base_white;
        display: flex;
        align-items: center;
        gap: 1.5rem;

        h1 {
            margin: 0;
        }

        .logo {
            font-weight: 400;
            font-size: 3.4375rem;
            letter-spacing: -0.24063rem;
        }
    }
}
