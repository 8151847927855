.modalSizing {
    width: 34rem !important;
}

.fieldSelection {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;

        section {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .optionGroup {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
            }
        }
    }
}
