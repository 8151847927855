.addEditTab {
    padding: 1.2rem 1.4rem;
    width: 100%;
    .toggle {
        display: flex;
        flex-direction: row;
        margin-top: 1.4rem;
        gap: 1rem;
        label {
            font-size: 1rem;
            font-weight: 400;
        }
    }
    label {
        margin-top: 0;
    }

    .inputField {
        max-width: unset;
    }
}
