.buttonGroup {
    display: flex;
    flex-wrap: nowrap;

    button {
        &:first-child {
            border-radius: 0.25rem 0 0 0.25rem;
        }

        &:last-child {
            border-radius: 0 0.25rem 0.25rem 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
    }
}
