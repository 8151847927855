@use 'styles/borders';

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    @extend %thin-bottom;

    h1 {
        margin: 0;
    }
}
