@use 'styles/colors';
.subsection {
    border-radius: 0.3125rem;
    border: 1px solid colors.$base-lighter;
    background: colors.$base-white;

    .header {
        display: flex;
        padding: 1.5rem;
        justify-content: space-between;
        flex: 1 0 0;
        align-self: stretch;
        background: colors.$cool-accent-lighter;

        .name {
            h4 {
                color: colors.$base-black;
                font-size: 1rem;
                font-weight: 700;
                margin: 0 0 0.5rem;
            }
        }
        .info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;


            .count {
                color: colors.$base-darkest;
                font-size: 0.875rem;
                font-weight: 400;
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            gap: 0.625rem;

            svg {
                color: colors.$primary;
                cursor: pointer;
            }
        }
        &.grouped {
            background-color: colors.$primary-lighter;
        }
        .indicator {
            background-color: colors.$primary-dark;
            display: flex;
            justify-content: center;
            align-items: center;
            color: colors.$base-white;
            width: 2rem;
            height: 2rem;
        }
    }
    .footer {
        display: flex;
        justify-content: flex-end;
    }
}
#confirmation .usa-modal #confirmation-description {
    font-weight: 300;
}
