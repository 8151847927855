@use 'styles/colors';
@use 'styles/borders';

.filters {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        justify-content: space-between;

        padding: 1.25rem;

        @extend %thin-bottom;

        svg {
            cursor: pointer;
        }

        label {
            color: var(--base-black, #000);
            text-align: center;
            font-size: 1.375rem; // h5
            font-weight: 700;
        }
    }

    section {
        padding: 1.25rem;
    }

    button.addNew {
        text-decoration: none;
        font-weight: 700;
        color: #005ea2;
        padding: 0;
    }

    footer {
        border-top: 1px solid var(--base-lighter, #dcdee0);
        display: flex;
        justify-content: flex-end;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        .clearButton {
            border: none;
            box-shadow: none;
        }
    }
}
