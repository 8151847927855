@use 'styles/colors.scss';
@use 'styles/borders.scss';

.import-template {
    display: flex;
    flex-direction: column;
    position: relative;

    .banner {
        padding: 1rem 3rem 0 3rem;
    }
    .spinner {
        height: 100%;
    }
    .field-space {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .main-header-title {
        font-size: 22px;
    }
    .drop-container {
        height: 60vh;
        .heading {
            padding: 40px 24px 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            h2 {
                font-size: 1rem;
                margin: 0;
            }
        }
        .drop-area {
            display: flex;
            padding: 32px;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            margin: 24px;
            cursor: pointer;
            border-radius: 8px;
            border: 1px dashed colors.$base-light;
            transition: background-color 0.5s ease;

            &.dragged {
                background-color: colors.$disabled-light;
            }
            svg {
                transform: rotate(-90deg);
                color: colors.$base-light;
            }
            .gap-10 {
                gap: 10px;
            }
            .tag-cover {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 4px 6px 4px 2px;
                border-radius: 2px;
                color: colors.$base-white;
                background: colors.$primary;
                .usa-icon {
                    cursor: pointer;
                }
            }
            label {
                color: #000;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 162%;
                span {
                    color: colors.$primary;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    cursor: pointer;
                }
            }
        }
    }

    .button-container {
        padding: 1.5rem;
        @include borders.bordered($color: colors.$base-lightest);

        .submit-btn {
            float: right;
            border: 2px solid colors.$primary;
            border-radius: 0.25rem;
        }
        .cancel-btn {
            float: right;
            margin-right: 0.5rem;
            border: 2px solid colors.$primary;
            background: colors.$base-white;
            color: colors.$primary;
            border-radius: 0.25rem;
        }
    }

    .usa-button {
        &:disabled {
            border: 2px solid colors.$disabled;
            background: colors.$disabled;
            color: colors.$base-white;
        }
    }
}
