@use 'styles/colors';

.heading {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.alert-banner {
    p {
        color: colors.$base-darkest;
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.sign-in {
    margin-top: 1rem;
    line-height: 1.25rem;
}

.participation {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid colors.$base-lighter;
    border-bottom: 1px solid colors.$base-lighter;
    margin-top: 1rem;

    button {
        font-size: 14px;
        padding: 0;
        text-decoration: none;
    }
}
