@use 'styles/colors';

.add-business-rules-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    align-self: stretch;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid var(--Base-lighter, #dcdee0);
    background: var(--Base-White, #fff);

    .business-rules-header {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.business-rules-table {
    thead tr th {
        &:nth-of-type(1) {
            width: 29.6rem;
        }
        &:nth-of-type(2) {
            width: 11.9rem;
        }
        &:nth-of-type(3) {
            width: 9.19rem;
        }
        &:nth-of-type(4) {
            width: 9.25rem;
        }
        &:nth-of-type(5) {
            width: 19.9rem;
        }
        &:nth-of-type(6) {
            width: 4.44rem;
        }
    }
}

.no-data-available {
    width: 100%;
    padding: 0;
    margin: 0;

    .no-data-info {
        display: block;
        width: 100%;
        text-align: center;
        border-radius: 0.3125rem;
        border: 1px solid colors.$base-lighter;
        padding: 1.5rem 0rem;
        margin: 0;

        .no-items {
            color: colors.$base-black;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.25rem;
            margin: 0;
        }
        p {
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 162%;
        }
    }
}
