.col-type {
    width: 40%;
}

.col-authority {
    width: 30%;
}

.col-id {
    width: 30%;
}
