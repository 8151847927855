@use 'styles/colors';
@use 'styles/borders';

.matchConfiguration {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;

    & > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: colors.$base-white;
        padding: 1rem;
        @extend %thin-bottom;

        a {
            display: flex;
            align-items: center;
            gap: 0.25rem;
        }
    }

    & > main {
        display: flex;
        flex-grow: 1;

        .formAndButtonBar {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            flex-grow: 1;

            .buttonBar {
                display: flex;
                justify-content: space-between;
                position: sticky;
                bottom: 0;
                padding: 1.5rem;
                background-color: colors.$base-white;
                @extend %thin-top;

                & > div {
                    display: flex;
                    gap: 0.5rem;
                }
            }
        }
    }
}
