@use 'styles/borders';

.overlay {
    --modal-min-width: 400px;

    display: flex;
    position: relative;
    align-items: center;

    dialog {
        position: absolute;
        z-index: 100;
        top: calc(100% + 0.5rem);
        right: 0.5rem;

        min-width: var(--modal-min-width);
        padding: 0;

        border-radius: 5px;
        @extend %thin;

        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .left {
        right: auto;
    }

    .right {
        left: auto;
    }
}
