$base-white: #ffffff;
$base-lightest: #f0f0f0;
$base-lighter: #dcdee0;
$base-light: #a9aeb1;
$base: #71767a;
$base-dark: #565c65;
$base-darker: #3d4551;
$base-darkest: #1b1b1b;
$base-black: #000000;

$primary-lightest: #f0f7fd;
$primary-lighter: #d9e8f6;
$primary-light: #73b3e7;
$primary: #005ea2;
$primary-vivid: #0050d8;
$primary-dark: #1a4480;
$primary-darker: #162e51;

$secondary-lighter: #f8dfe2;
$secondary-light: #f2938c;
$secondary: #d83933;
$secondary-vivid: #e41d3d;
$secondary-dark: #b51d09;
$secondary-darker: #8b1303;

$cool-accent-lightest: #f0f9fb;
$cool-accent-lighter: #e1f3f8;
$cool-accent-light: #97d4ea;
$cool-accent: #00bde3;
$cool-accent-dark: #28a0cb;
$cool-accent-darker: #07648d;

$warm-accent-lighter: #f2e4d4;
$warm-accent-light: #ffbc78;
$warm-accent: #fa9441;
$warm-accent-dark: #c05600;
$warm-accent-darker: #775540;

$info-lighter: #e7f6f8;
$info-light: #99deea;
$info: $cool-accent;
$info-dark: #009ec1;
$info-darker: #2e6276;

$error-lighter: #f4e3db;
$error-light: #f39268;
$error: #d54309;
$error-dark: #b51d09;
$error-darker: #6f3331;

$warning-lighter: #faf3d1;
$warning-light: #fee685;
$warning: #ffbe2e;
$warning-dark: #e5a000;
$warning-darker: #936f38;

$success-lighter: #ecf3ec;
$success-light: #70e17b;
$success: #00a91c;
$success-dark: #4d8055;
$success-darker: #446443;

$disabled-light: #e6e6e6;
$disabled: #c9c9c9;
$disabled-dark: #adadad;
$disabled-darker: #757575;
$disabled-darkest: #454545;

$border: #dfe1e2;

$mandatory: #d83933;
$requried: $mandatory;

$visited-link: #562b97;
$background: #f1f6f9;
$focused: #2491ff;

$clear: rgba(0, 0, 0, 0);
$destructive: #ec3823;

$open: #008817;
