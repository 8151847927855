.modal {
    height: calc(100% - 9.5rem);
    overflow-y: auto;
    .content {
        p {
            margin: 0;
        }
        .top {
            display: flex;
            gap: 1rem;
            padding: 0.9rem 1.5rem;
        }
    }
}
