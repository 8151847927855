@use 'styles/colors';
@use 'styles/borders';

.dataRow {
    display: flex;
    width: 100%;
    min-height: 3rem;
    padding: 0.5rem 1.75rem;
    gap: 2rem;
    align-items: center;
    @extend %thin-bottom;
    font-size: 0.875rem;

    .title {
        line-height: 162%;
        width: 13rem;
        color: colors.$base-darkest;
        font-weight: 700;
    }

    .value {
        color: colors.$disabled-darkest;
    }
}
