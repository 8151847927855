@use 'styles/colors';
.searchBar {
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    padding: 16px 24px 16px 16px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 5px;
    background: colors.$base-lightest;
    .searchFilter {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 2.5rem;
        flex-direction: row;
        gap: 0.5rem;
        button {
            margin: 0;
            padding: 0.5rem 1rem;
            background-color: colors.$base-white;
        }
    }

    input {
        width: 22.5rem;
        flex-shrink: 0;
        height: 2.5rem;
        margin-top: 0;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .field {
        display: flex;
        input:focus {
            outline-offset: -0.25rem !important;
        }
        .searchButton {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.25rem 1rem;
            background-color: colors.$primary;
            .searchIcon {
                width: 1.5rem;
                height: 1.5rem;
                flex-shrink: 0;
            }
        }
    }
}
