@use 'styles/colors';
@use 'styles/borders';

.actions {
    position: absolute;

    left: 0;
    z-index: 1;
    background: colors.$base-white;
    @extend %thin;
    @include borders.rounded();

    & > button {
        padding: 0.5rem;
        min-width: 12ch;

        text-decoration: none;

        color: colors.$base-dark;

        @extend %thin-bottom;

        &:hover {
            @extend %thin-bottom;
        }
    }
}
