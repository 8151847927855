@use 'styles/colors';
@use 'styles/borders';
@forward 'styles/buttons';

.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: colors.$base-lightest;
    padding: 1rem 1.5rem;

    .filterButton {
        background-color: colors.$base-white;
    }

    .search {
        height: 2.5rem;
        input {
            min-width: 36ch;
        }
    }

    menu {
        padding-inline-start: 0;
        margin: 0;
        display: flex;
        height: 2.5rem;
        gap: 0.5rem;
        > a,
        > button {
            background-color: colors.$base-white;
            display: flex;
            align-items: center;
            margin: 0;
            &:hover {
                background-color: colors.$base-white;
            }
        }
    }
}
