@use 'styles/colors';
@use 'styles/borders';

.configurationMessage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 0.5rem;
    padding: 3.75rem 1.5rem;
    background-color: colors.$base-white;
    @extend %thin;
    align-items: center;
    margin: 1rem;
}
