@use 'colors.scss';

%icon-button {
    padding: 0.5rem !important;
}

.icon {
    @extend %icon-button;
}

.destructive {
    background-color: colors.$destructive !important;
    &:hover {
        background-color: colors.$secondary-dark !important;
    }
    &:active {
        background-color: colors.$secondary-darker !important;
    }
}
