@use 'styles/colors';
@use 'styles/borders';

.editValueset {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        padding: 1.5rem;
        height: 100%;
        overflow-y: auto;

        .editValuesetButton {
            box-shadow: none;
            margin-right: 0 !important;
            color: colors.$primary;
            padding: 0;
        }

        .detailsHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
                color: colors.$primary;
                cursor: pointer;
            }
        }

        .sectionText {
            color: colors.$base-darker;
            font-size: 1.375rem;
            font-weight: 700;
        }

        .valuesetInfo {
            margin: 1rem 0;
            padding: 1.44rem;
            @extend %thin;
            @include borders.rounded();
            display: flex;
            justify-content: space-between;

            .data {
                max-width: 25%;
                .title {
                    font-size: 0.8125rem;
                    font-weight: 600;
                }

                div {
                    max-width: 100%;
                    word-wrap: break-word;
                }
            }
        }

        .noConceptsSection {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            @extend %thin;
            @include borders.rounded();
            padding: 1.25rem 0;
            text-align: center;
        }

        .addConceptLinkSection {
            display: flex;
            justify-content: flex-end;
            .addConceptButton {
                display: flex;
                align-items: center;
                cursor: pointer;
                color: colors.$primary;
                font-weight: 700;
                background: unset;
                border: none;

                svg {
                    margin-right: 0.38rem;
                    margin-top: -0.1rem;
                }
            }
        }
    }
}
