@use 'styles/colors';

.tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: colors.$base-darkest;
    padding: 1rem 1.5rem;
    background-color: colors.$base-white;
    .tabInfo {
        display: flex;
        flex-grow: 1;
        .handle {
            display: flex;
            margin-right: 1rem;
            img {
                cursor: pointer;
                width: 1rem;
                max-width: unset;
            }
            p {
                display: none;
            }
            &.inactive {
                img {
                    cursor: not-allowed;
                }
            }
        }
        .label {
            display: flex;
            align-items: center;
            width: 75%;
            gap: 1rem;
            font-size: 1rem;
            font-weight: 400;
            img {
                height: 1rem;
            }
        }
    }

    .buttons {
        color: colors.$primary;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        svg {
            height: 1.25rem;
            cursor: pointer;
            &:hover {
                color: colors.$primary-light;
            }
            &:nth-of-type(2) {
                &:hover {
                    color: colors.$error;
                }
            }
        }
    }
    .delete_buttons {
        display: none;
    }
    &.delete {
        background-color: colors.$warning-lighter;
        .handle {
            p {
                display: inline;
                font-weight: 700;
                font-size: 0.8rem;
            }
        }
        .buttons {
            display: flex;
            svg {
                display: none;
            }
            p {
                cursor: pointer;
            }
        }
        .delete_buttons {
            display: flex;
            gap: 1.5rem;
            width: 100%;
            justify-content: flex-end;
            p {
                font-size: 0.875rem;
                font-weight: 700;
                margin: 0;
                margin-top: 0.15rem;
                cursor: pointer;
                &:first-of-type {
                    width: 4.75rem;
                }
            }
        }
    }
    &.dragging {
        border: 1px solid colors.$primary;
        filter: drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
    }
}
