@use 'styles/colors';

.sections {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding-bottom: 8rem;

    .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        width: 60rem;
        justify-content: space-between;

        border-radius: 0.3125rem;
        border: 1px solid colors.$base-lighter;
        background: colors.$base-white;

        .header {
            padding: 1.5rem;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;

            border-radius: 0.3125rem;
            background: colors.$base-white;

            &.expanded {
                border-bottom: 1px solid colors.$base-lighter;
            }

            .info {
                .name {
                    h3{
                        color: colors.$base-black;
                        text-align: center;
                        font-size: 1.375rem;
                        font-weight: 700;
                        margin: 0;
                    }
                }

                .subsectionCount {
                    color: colors.$base-darkest;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.4175rem;
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                margin-right: 0 !important;
                padding: 0 !important;

                svg {
                    color: colors.$primary;
                    cursor: pointer;
                }

                .settingBtn {
                    box-shadow: none;
                    margin-right: 0 !important;
                }
            }
        }

        .subsectionWrapper {
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}
