@use 'styles/colors';

.entry {
    background: colors.$base-lighter;

    section {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}
