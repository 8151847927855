@use 'styles/colors';
@use 'styles/borders';

.section {
    background-color: colors.$base-white;
    @include borders.bordered();
    @include borders.rounded();
    margin-bottom: 1.5rem;

    .header {
        padding: 1.5rem;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;

        border-radius: 0.3125rem;
        background: colors.$base-white;

        &.expanded {
            border-bottom: 1px solid colors.$base-lighter;
        }
        h3 {
            color: colors.$base-black;
            text-align: center;
            font-size: 1.375rem;
            font-weight: 700;
            margin: 0;
        }
        .info {
            .subsectionCount {
                color: colors.$base-darkest;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.4175rem;
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            margin-right: 0 !important;
            padding: 0 !important;

            svg {
                color: colors.$primary;
                cursor: pointer;
            }

            .settingBtn {
                box-shadow: none;
                margin-right: 0 !important;
            }
        }
    }

    .subsectionWrapper {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
