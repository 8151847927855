@use 'styles/colors';

@mixin indicator($background, $stripe) {
    background-color: $background;
    border-left: 8px solid $stripe;
}

.alert-banner {
    width: 100%;
    padding: 0.6rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;
    position: relative;
    &.hidden {
        display: none;
    }
    &.success {
        @include indicator(colors.$success-lighter, colors.$success);
    }
    &.warning {
        @include indicator(colors.$warning-lighter, colors.$warning);
    }
    &.prompt {
        @include indicator(colors.$info-lighter, colors.$success);
    }
    &.info {
        @include indicator(colors.$info-lighter, colors.$info);
    }
    &.error {
        @include indicator(colors.$error-lighter, colors.$error);
    }
    &__left,
    &__right {
        display: flex;
        align-items: center;
    }
    &__close {
        cursor: pointer;
        position: absolute;
        top: 0.75rem;
        right: 1.5rem;
        svg {
            height: 1.2rem;
            width: 1.2rem;
        }
    }
    p {
        margin: 0;
        font-size: 0.8rem;
        span {
            font-weight: 700;
        }
    }
}
