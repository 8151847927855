@use 'styles/colors';

.usa-js-modal--active {
    .usa-modal__heading {
        padding: 1.5rem;
        margin-bottom: 0;
        border-bottom: 1px solid colors.$base-lightest;
        position: relative;
        width: 100%;
    }
    .usa-modal__footer {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        justify-content: space-between;
        padding: 1.5rem;
        border-top: 1px solid colors.$base-lightest;
    }
}
.usa-modal {
    .usa-modal__heading {
        padding: 1.5rem;
        margin-bottom: 0;
        border-bottom: 1px solid colors.$base-lightest;
        h3 {
            margin: 0;
        }
        .usa-button {
            color: colors.$base-white;
            &:last-child {
                position: absolute;
                top: 0.25rem;
                right: 0.25rem;
            }
        }
        .usa-button--unstyled {
            color: colors.$base-darkest;
        }
    }
    &.tall {
        min-height: 50rem;
        height: 50rem;
        .usa-modal__heading {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .usa-button {
                position: relative;
                margin: 0;
                top: unset;
                right: unset;
            }
        }
        .usa-modal__content {
            height: 100%;
        }
        .usa-modal__main {
            height: 100%;
        }
        .usa-modal__footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5.6rem;
            padding: 1.5rem;
            background: white;
            border-radius: 0 0 0.5rem 0.5rem;
            display: flex;
            justify-content: flex-end;
            button {
                margin: 0;
                &:last-of-type {
                    margin-left: 0.5rem;
                }
            }
        }
    }
    &.wide {
        width: 66.25rem;
        max-width: 66.25rem;
        height: 100%;
        max-height: 85vh;
        .usa-modal__heading {
            padding: 1rem;
            margin: 0;
        }
        .usa-modal__content {
            overflow: hidden;
            height: 100%;
            position: relative;
            border-radius: 0;
            .usa-modal__main {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                max-height: 85vh;
                overflow: hidden;
            }
        }
        .usa-modal__footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5.6rem;
            padding: 1.5rem;
            background: white;
            border-radius: 0 0 0.5rem 0.5rem;
            display: flex;
            justify-content: flex-end;
            button {
                margin: 0;
                &:last-of-type {
                    margin-left: 0.5rem;
                }
            }
        }
    }
    &.width {
        width: 66.25rem;
        max-width: 66.25rem;
        height: 19rem;
        max-height: 85vh;
        .usa-modal__heading {
            padding: 1rem;
            margin: 0;
        }
        .usa-modal__content {
            overflow: hidden;
            height: 100%;
            position: relative;
            border-radius: 0;
            .usa-modal__main {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                max-height: 85vh;
                overflow: hidden;
            }
        }
        .usa-modal__footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5.6rem;
            padding: 1.5rem;
            background: white;
            border-radius: 0 0 0.5rem 0.5rem;
            display: flex;
            justify-content: flex-end;
            button {
                margin: 0;
                &:last-of-type {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}
