@use 'styles/modal.scss';

.verified-address-selection {
    --difference-color: #fa9441;

    display: flex;
    justify-content: space-around;

    .address-line {
        display: block;
        padding-top: 0.25rem;

        .difference {
            font-weight: bold;
            color: var(--difference-color);
        }
    }
}
