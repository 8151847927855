@use 'styles/colors';
@use 'styles/borders';

.addPatientForm {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-right: 1rem;

    .formCard {
        background-color: colors.$base-white;

        @extend %thin;
        @include borders.rounded();
    }

    .formContent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 12rem;

        input {
            width: 100% !important;
        }
        select {
            width: 100% !important;
        }
    }

    .errorList {
        list-style: inside;
        padding: 0;
        margin: 0;

        & > li {
            line-height: 1.62rem;
        }
    }
}
