@use 'styles/colors';
@use 'styles/borders';

.addSection {
    height: 100%;
    width: auto;
    min-width: auto;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.375rem;
        padding: 1.5rem;
        border-bottom: 1px solid colors.$base-lightest;
        h2 {
            margin: 0;
        }
    }

    .form {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: auto;
        height: 100%;
        max-width: unset;
        max-height: unset;
        flex-shrink: 1;
        overflow-y: auto;

        .content {
            padding: 1.5rem 1.5rem 2.5rem 1.5rem;
            width: auto;

            .inputField {
                width: 100%;
            }

            .visibleToggle {
                display: flex;
                flex-direction: row;
                padding-top: 0.5rem;

                .toggleBtn {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }
    .footer {
        display: flex;
        width: auto;
        padding-top: unset;
        justify-content: flex-end;
        display: flex;
        position: relative;
        align-items: flex-end;
        border-top: 1px solid colors.$base-lightest;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        @include borders.rounded();

        .footerBtns {
            justify-content: flex-end;
            margin-right: 1.5rem;
            margin-top: 1rem;
        }
    }
}
