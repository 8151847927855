.patient_results {
    tbody tr td a {
        font-weight: 700;
    }
}

.col-patientid {
    width: 8rem;
    min-width: 8rem !important;
    max-width: 10rem !important;
}

.col-patientname {
    min-width: 14rem;
}

.col-dob {
    min-width: 7rem;
}

.col-sex {
    min-width: 6rem;
}

.col-address {
    min-width: 21rem;
}

.col-phone {
    min-width: 10rem;
}

.col-id {
    min-width: 9rem;
}

.col-email {
    min-width: 14rem;
}