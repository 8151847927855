@forward 'styles/buttons';
@use 'styles/colors';

.footer {
    display: flex;
    justify-content: space-between;

    .cta {
        margin-left: auto;
    }

    .delete {
        color: colors.$error;
        text-decoration: none !important;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}
