@use 'styles/colors';
.subsection {
  margin: 1rem;
  border-radius: 0.3125rem;
  border: 1px solid colors.$base-lighter;
  background: colors.$base-white;

  .header {
    display: flex;
    padding: 1.5rem;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    background: colors.$cool-accent-lighter;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      .name {
        h4 {
          color: colors.$base-black;
          text-align: center;
          font-size: 1rem;
          font-weight: 700;
          margin: 0 0 0.5rem;
        }
      }
      .count {
        color: colors.$base-darkest;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      svg {
        color: colors.$primary;
        cursor: pointer;
      }
    }
    &.grouped {
      background-color: colors.$primary-lighter;
    }
    .indicator {
      background-color: colors.$primary-dark;
      display: flex;
      justify-content: center;
      align-items: center;
      color: colors.$base-white;
      width: 2rem;
      height: 2rem;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
  .grouped {
    .groupedForm {
      display: flex;
      border-bottom: 2px solid colors.$border;
      .groupedQuestionName {
        width: 100%;
        padding: 1rem 1rem 0.5rem;
        border-left: 1px solid colors.$border;
        &:first-child {
          border: none;
        }
        span {
          font-weight: 700;
        }
      }
    }
    .groupedInfo {
      padding-left: 1rem;
    }
    .groupedQuestionsSection {
      margin: 1rem 2rem 1rem;
      border: 1px solid colors.$border;
      border-radius: 0.3125rem 0.3125rem;
    }
    .footer {
      padding: 0 1.5rem 1rem;
    }
  }
}
#confirmation .usa-modal #confirmation-description {
  font-weight: 300;
}
