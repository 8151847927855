@use 'styles/borders';
@use 'styles/colors';

.header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    flex-shrink: 0;

    border-radius: 0.3125rem 0.3125rem 0rem 0rem;
    background: colors.$base-white;
    @extend %thin-bottom;

    .title {
        color: colors.$base-black;
        text-align: center;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .closeButton {
        background-color: unset;
        border: none;
        display: flex;
        cursor: pointer;
    }
}
