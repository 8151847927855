@use 'styles/colors';

.testName {
    color: colors.$base-darkest;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.4175rem;
}
.testResult {
    color: colors.$base-darkest;
    font-size: 0.875rem;
    line-height: 1.4175rem;
}
