@forward 'styles/buttons';
@use 'styles/colors';
@use 'styles/modal';
@use 'styles/borders';

.modal {
    --modal-height: #{modal.$max-height};
    --header-height: 5.0625rem;
    --footer-height: 5.25rem;

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding: 1.5rem;
        max-height: var(--header-height);

        svg {
            cursor: pointer;
        }

        h2 {
            margin: 0;
        }
    }
    .content {
        min-height: var(--footer-height);
        max-height: calc(var(--modal-height) - var(--header-height));

        overflow: hidden;

        & > section {
            overflow-y: scroll;
            max-height: calc(var(--modal-height) - var(--header-height) - var(--footer-height));
        }
    }

    footer {
        display: flex;
        justify-content: space-between;
        gap: 0.8 rem;
        padding: 1.5rem;
        max-height: var(--footer-height);
        @extend %thin-top;

        & > .cta {
            margin-left: auto;
        }

        & > .delete {
            color: colors.$error;
            text-decoration: none !important;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
}
