@use 'styles/colors';
@use 'styles/borders';

.passList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1rem;
    width: 18.75rem;
    height: 100%;
    background-color: colors.$base-white;
    @extend %thin-right;

    .passEntries {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > section:last-of-type {
            @extend %thin-bottom;
        }

        & > button {
            text-decoration: none;
            margin-top: 0.5rem !important;
        }
    }
}
