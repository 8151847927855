@use 'styles/colors';
@use 'styles/borders';


.manageButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    h2 {
        font-size: 1rem;
        margin: 0;
    }
}

.manageTabModal {
    display: flex;
    flex-direction: column;
    height: 100%;

    .modalBody {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
    }

    .buttonBar {
        padding: 1.5rem;
        @extend %thin-top;
        display: flex;
        justify-content: flex-end;
    }
}
