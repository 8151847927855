@use 'styles/colors';
@use 'styles/borders';

.matchingBoundsForm {
    background-color: colors.$base-white;
    @extend %thin;

    & > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;
        @extend %thin-bottom;

        .badges {
            display: flex;
            gap: 0.5rem;
            color: colors.$base-black;
            font-weight: 400;

            .noMatch {
                padding: 0.25rem 0.5rem;
                border-radius: 0.125rem;
                background: colors.$disabled-light;
            }

            .reviewMatch {
                padding: 0.25rem 0.5rem;
                border-radius: 0.125rem;
                background: colors.$warning;
            }

            .automaticMatch {
                padding: 0.25rem 0.5rem;
                border-radius: 0.125rem;
                background: colors.$success;
                color: colors.$base-white;
            }
        }
    }

    .formContent {
        padding: 0 1.5rem 1.5rem 1.5rem;
        .inputs {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 3.25rem 0.5rem 0;
            gap: 1.5rem;

            input {
                width: 5rem;
                height: 2.5rem;
                padding: 0.5rem;
                border-radius: 0;
                border: solid 1px colors.$base-dark;
            }
        }
    }
}
