@mixin mobile {
    @media (max-width: 599px) { @content; }
}
@mixin tablet {
    @media (max-width: 1024px) { @content; }
}
@mixin desktop {
    @media (max-width: 1200px) { @content; }
}
@mixin desktop-xl {
    @media (min-width: 1201px) { @content; }
}