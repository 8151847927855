@use 'styles/colors';
@use 'styles/borders';

.header {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    background-color: colors.$base-white;

    padding: 1.5rem;
    padding-bottom: 0;

    margin-bottom: 1.56rem;

    @include borders.bordered();
    @include borders.rounded();

    .titleContainer {
        display: flex;
        justify-content: space-between;

        .title {
            h2 {
                font-size: 1.375rem;
                margin: 0;
            }
            p {
                max-width: 36ch;
                font-weight: 400;
                color: colors.$base-darkest;
                line-height: 1.62rem;
                word-wrap: break-word;
            }
        }
    }
}
