@use 'styles/colors';

.patient-summary {
    .patient-summary-title {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        justify-content: flex-end;
        font-weight: 700;

        & > * {
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
        }

        h2 {
            font-weight: 700;
            margin-right: auto;
            font-size: 1.375rem;
        }

        span {
            display: block;
            font-size: 1.375rem;
            margin-right: 1rem;
        }
    }

    .patient-summary-items {
        padding: 1.5rem;
        display: flex;
        flex-wrap: wrap;

        .patient-summary-item {
            padding-left: 0.75rem;
            padding-right: 0.75rem;

            .patient-summary-item-label {
                display: block;
                color: colors.$base;
                text-transform: uppercase;
                font-weight: normal;
                margin-bottom: 0.25rem;
            }
            p {
                margin-top: 0;
                word-break: break-word;
                white-space: pre-wrap;
                font-weight: 400;

                &.email {
                    max-width: 3rem;
                }
            }
        }
    }

    .grouped {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;

        .patient-summary-item {
            width: 25%;
        }
    }

    .stacked {
        flex-grow: 0;

        .patient-summary-item {
            width: 100%;
        }
    }
}
