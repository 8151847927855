.usa-modal-wrapper {
    .address-section {
        display: flex;
        justify-content: space-between;
    }
    .verified-button-group {
        justify-content: center;
    }
    .verified-modal {
        max-width: 50em;
    }
}

.content {
    background-color: #f1f6f9;
    width: 100%;

    .address-fields-grid {
        .button-group {
            margin-left: 0.45em;
            margin-right: -0.45em;
            ul {
                width: 100%;
                filter: none;
                border: 1px solid black;
                border-top: 0;
                border-radius: 0;

                .address-suggestion-line {
                    display: block;
                }

                .usa-nav__submenu-item .address-suggestion-line:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.max-width-full {
    max-width: 100% !important;
}

.left-bar .active {
    font-weight: 600;
    color: #005ea2;
}

.left-bar .line {
    height: 100%;
    width: 6px;
    background-color: #005ea2;
    border-radius: 5px;
}

.left-bar .cursor-pointer:last-child {
    border-bottom: 1px solid #a9aeb1;
}

.content-container {
    margin-left: 15px;
    position: sticky;
    top: 80px;
    align-self: start;
    .content-sidebar {
        a {
            display: block;
            padding: 0.25rem 15px;
            font-size: 14px;
            text-decoration: none;
            color: #005ea2 !important;
            font-weight: normal !important;
            border-left: 4px solid transparent !important;
            font-size: 0.85rem;

            &:focus {
                outline: none !important;
                background-color: transparent !important;
            }

            &.visible {
                color: #1b1b1b !important;
                font-weight: bold !important;
                border-left: 4px solid #1b1b1b !important;
                transition: 0.2s ease-in;
            }

            &.visible ~ a {
                color: #005ea2 !important;
                font-weight: normal !important;
                border-left: 4px solid transparent !important;
            }
        }
    }
}

.fields {
    padding: 0.75rem 1.5rem 1.5rem 1.5rem;
}

.ext-field {
    padding-right: 0 !important;
}

.new-patient-title {
    font-size: 2rem;
}

.content-navigation-title {
    font-size: 1.375rem;
}

.nav-buttons {
    display: flex;
    gap: 1rem;
}
