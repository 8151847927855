@use 'styles/colors';
@use 'styles/borders';

.block {
    @extend %thin;
    @include borders.rounded();
    .header {
        padding: 1rem 1rem;
        background-color: colors.$cool-accent-lighter;
    }
    .row {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        gap: 0.25rem;
        .number {
            width: 3%;
        }
        .name {
            width: 40%;
        }
        .appears {
            width: 20%;
        }
        .label {
            width: 22%;
        }
        .width {
            width: 15%;
        }
        &.header {
            background-color: colors.$base-white;
            border-bottom: 2px solid colors.$base-lighter;
            font-size: 1rem;
            font-weight: 700;
            padding: 0.5rem 0 1rem;
            p {
                margin: 0;
            }
            .appears {
                width: 20%;
                p::after {
                    content: '*';
                    color: colors.$secondary;
                }
            }
            .label {
                width: 22%;
                p::after {
                    content: '*';
                    color: colors.$secondary;
                }
            }
            .width {
                width: 15%;
                p::after {
                    content: '*';
                    color: colors.$secondary;
                }
            }
        }
    }
    .table {
        width: 100%;
        padding: 1rem;
        tfoot tr td {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.85rem;

            .currentPercent {
                font-weight: bold;
                margin-left: 1rem;
                color: colors.$secondary-vivid;
            }
            &.valid div {
                color: colors.$base-darkest;
            }
        }
    }
}
