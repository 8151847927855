@use 'styles/colors';

.loading {
    border-radius: 50%;
    height: 4.6875rem;
    width: 4.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered {
    margin: auto;
}

.white {
    background-color: colors.$base-white;
}

.colored {
    background-color: colors.$primary;
}

.icon {
    display: inline-block;
    border: 0.35rem solid colors.$base-lighter;
    border-top-color: colors.$base-darker;
    border-radius: 50%;
    height: 3.125rem;
    width: 3.125rem;
    animation: spin 1.5s linear infinite;
}

.screen-reader {
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
