@use 'styles/colors';
@use 'styles/borders';

.preview {
    display: grid;
    grid-template-columns: minmax(25.5rem, 1fr) 3fr;
    gap: 1.5rem;
}
.loaderContent {
    padding: 4rem;
    .loaderIcon {
        margin-bottom: 1.5rem;
    }
    .loaderText {
        display: flex;
        justify-content: center;
    }
}
.icons {
    display: flex;
    gap: 0.5rem;
    button,
    a {
        margin: 0;
    }
    .link {
        padding: 0.4rem 0.75rem;
        border-radius: 0.25rem;
    }
}
