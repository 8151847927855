@use 'styles/colors';

.question {
    .tile {
        background-color: colors.$base-white;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.9rem 1rem 0.9rem 8.25rem;
        img {
            width: 1.2rem;
            height: 1.2rem;
        }
        .handle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.2rem;
            height: 1.2rem;
        }
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 2.4rem);
        }
        &.hidden {
            height: 0px;
        }
    }
    &.dragging .tile{
        border: 1px solid colors.$primary;
        filter: drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
    }
}
