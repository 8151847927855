@use 'styles/colors';
@use 'styles/borders';

.more-options {
    position: relative;
    .more-options__header {
        cursor: pointer;
        &.active::after {
            content: '';
            position: absolute;
            top: 0;
            left: -0%;
            width: 100%;
            height: 90%;
            z-index: 0;
            border-radius: 50%;
            background-color: colors.$base-light;
            opacity: 0.25;
        }
    }
    .more-options__menu {
        display: flex;
        flex-direction: column;
        z-index: 999;
        position: absolute;
        top: 2.2rem;
        left: 0.4rem;
        min-width: 8rem;
        border-radius: 5px;
        @extend %thin;
        background: var(--base-white, #fff);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
        button {
            margin: 0;
            background-color: unset;
            color: colors.$base-dark;
            text-align: initial;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            svg,
            img {
                margin: 0;
                height: 1.4rem;
                width: 1.4rem;
                color: colors.$base-dark;
            }
        }
    }
}
