@use 'styles/colors';

.results {
    overflow-x: scroll;
    height: 100%;

    .result {
        padding: 0.75rem 1rem;

        &:nth-child(odd) {
            background-color: colors.$base-lightest;
        }
    }
}
