@use 'styles/colors';
@use 'styles/components';

@mixin highlight($color) {
    border-left-color: $color;

    input,
    select,
    textarea {
        outline: none;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        border-width: 4px;
        border-style: solid;
        border-color: $color;
    }
}

.entry {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: colors.$clear;

    label {
        margin: 0;
        color: colors.$base-darkest;
    }

    input,
    select,
    textarea {
        box-sizing: border-box;
        margin: 0;
    }

    &.compact {
        @extend %compact;

        label {
            @extend %compact;
        }

        input,
        select {
            @extend %compact-input;
        }

        textarea {
            @extend %compact-textarea;
        }
    }

    &.error {
        @include highlight(colors.$error-dark);
    }

    &.warn {
        @include highlight(colors.$warning);
    }
}
