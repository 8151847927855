.spinner {
    z-index: 9999;
    background-color: rgba(1, 1, 1, 0.2);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100vh;
}
