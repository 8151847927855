@use 'styles/colors';

.message {
    --badge-color: #{colors.$disabled};

    display: flex;

    .information {
        --badge-color: #{colors.$info};
    }

    .success {
        --badge-color: #{colors.$success};
    }

    .warning {
        --badge-color: #{colors.$warning};
    }

    .error {
        --badge-color: #{colors.$error};
    }

    .badge {
        border-left-width: 8px;
        border-left-color: var(--badge-color);
        border-left-style: solid;
        color: var(--badge-color);

        padding-left: 1rem;
        padding-right: 1rem;

        fill: currentColor;
    }

    .content {
        .summary {
            font-size: 1.375rem;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 1.88rem;
        }
    }
}
