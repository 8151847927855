@use 'styles/colors';
@use 'styles/borders';
.section {
    background-color: colors.$base-white;
    @include borders.bordered();
    @include borders.rounded();

    .header {
        padding: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @extend %thin-bottom;

        .collapseIcon {
            cursor: pointer;
        }
        h3 {
            font-size: 1.35rem;
            margin: 0;
        }
    }

    .sectionContent {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
