@use 'styles/borders';
.question {
    padding: 0.75rem 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-of-type(2) {
        padding-top: 1.5rem;
    }
    &:last-of-type {
        padding-bottom: 1.5rem;
    }
    .borderedContainer {
        border-radius: 0.3125rem 0.3125rem;

        @extend %thin;
    }
}
