@forward 'apps/patient/profile/profile-modal';
@use 'styles/borders';

.modalHeader {
    @extend %thin-bottom;
}

.overflow {
    .content {
        overflow: visible;

        & > section {
            overflow: visible;
        }
    }
}
